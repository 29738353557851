import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Divider,
} from "@material-ui/core";
import ImageIcon from '@material-ui/icons/Image';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header.web";
import "../assets/Catalogue.scss"
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getResoursesList()
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <Header type={"Catalogue"} navigation={this.props.navigation} />
        <Box width={"100%"} height={"auto"} style={{ fontFamily: 'Nunito-Regular', background: "linear-gradient(rgb(231, 239, 252) 100%, rgb(255, 255, 255) 100%" }}>
          <Box width={"100%"} display={'flex'} justifyContent={"center"} marginTop={'1.5rem'}>
            <Box sx={webStyle.productContainer}>
              <Box
                style={{
                  width: "55rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  paddingBottom: "0",
                }}
              >
                <h1 style={{ color: "black", marginBottom: "0.5rem", marginLeft: 0 }}>
                  Resource Hub
                </h1>

                <Box style={{ display: "flex", gap: "1rem", marginTop: "0" }}>
                  {this.state.tabs?.map((value: any, index: number) => {
                    return <Button
                      className={`button-tabs ${this.handleUiActiveTab(String(index + 1))}`}
                      variant="text"
                      onClick={this.handleActiveTab.bind(this, String(index + 1), value?.tab)}
                    >
                      {value.name}
                    </Button>
                  })}
                </Box>
              </Box>
              <Divider style={{ width: '80rem', marginTop: "16px" }} />
              <Box style={{ display: "flex", flexWrap: "wrap", gap: "2.5rem", borderRadius: '1.5rem', marginTop: "16px", width: '100%' }}>
                {this.state.cardsData?.map((card, index) => {
                  return this.state.tabs?.map((value: any) => {
                    return card?.attributes?.[this.handleReturnCardmap(value)]?.map((cardValue: any) => {
                      return <Card key={index} style={{ width: '18rem', height: "20rem", boxShadow: '7', borderRadius: '1.2rem', backgroundColor: 'linear-gradient(rgb(231, 239, 252) 100%, rgb(255, 255, 255) 100%' }}>
                        <CardMedia
                          component="img"
                          style={{ height: "200px" }}
                          alt={`Image ${index + 1}`}
                          image={cardValue?.url}
                          title={`Image ${index + 1}`}
                        />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', paddingBottom: '16px', height: '9rem' }}>
                          <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <ImageIcon style={{ height: '2rem', width: '2rem', marginRight: '8px', marginBottom: '2px', color: '#5497FF' }} />
                            <Typography variant="h6" style={{ color: "#475569", fontWeight: 600, fontSize: "14px" }}>
                              Ocean Acidification
                            </Typography>
                            <Typography variant="body2" style={{ backgroundColor: '#F6F0FF', color: '#475569', marginLeft: '28px', borderRadius: '40px' }}>
                              {card?.attributes?.language}
                            </Typography>
                          </Box>
                          <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: '8px' }}>
                            {card?.attributes?.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    })
                  })
                })}
              </Box>
            </Box>
          </Box>
        </Box >
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  productContainer: {
    boxShadow: '4',
    display: "flex",
    flexWrap: "wrap",
    borderRadius: '8px',
    padding: '1rem 2rem',
    width: "80rem",
    backgroundColor: 'white',
    marginTop: '144px',
    marginBottom: '25px'
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End
