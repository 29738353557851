import React, { useState } from "react";
import {
    Paper,
    ListItemText,
    Box,
    Grid,
    FormControl,
    FormLabel,
    TextField
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { styled } from '@material-ui/styles';
import { Divider } from "react-native-elements";
import { t } from "i18next";

export const CustomTextField = styled(TextField)(() => ({
    background: '#FFFFFF',
    '& fieldset': {
        border: 'none'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment': {
        top: '8px',
        right: '9px',
        marginRight: '4px',
    },
    '& .MuiAutocomplete-popupIndicator': {
        '&.MuiAutocomplete-popupIndicatorOpen': {
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
            height: '38px',
        },
    },
    '& .MuiAutocomplete-inputFocused': {
        '&:focus': {
            borderRadius: "4px",
        },

    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        paddingLeft: '12px'
    },
    '& .MuiAutocomplete-clearIndicator': {
        display: 'none',
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        borderRadius: '8px 8px 0 0'
    },
    "&.enableCont":
    {

        border: '1px solid #CBD5E1',
        borderBottom: 'none',
        borderRadius: '8px 8px 0 0',
    },
    "&.disableCont":
    {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
    },
}));

export const CustomPaper = styled(Paper)(() => ({
    borderRadius: '0 0 8px 8px',
    borderTop: 'none',
    overflowY: 'auto',
    backgroundColor: '#fff',
    margin: 0,
    width: '100%',
    borderLeft: '1px solid #CBD5E1',
    borderRight: '1px solid #CBD5E1',
    borderBottom: '1px solid #CBD5E1',
    scrollbarWidth: 'none',
    boxShadow: 'none',
    '& .MuiAutocomplete-listbox': {
        padding: 0,
        margin: '0px 10px 0px 0px',
        maxHeight: '20vh',
        '&::-webkit-scrollbar': {
            width: '3px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CFCFCF',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-tracks': {
            backgroundColor: '#F1F5F9',
            borderRadius: '4px',
        },
    },
}));

interface OptionsType {
    title: string
}
interface InterfaceProps {
    options: OptionsType[]
    title: string;
    initialValue: any;
    onChange: Function;
    placeholderText: string
}

const LanguageDropDown = (props: InterfaceProps) => {
    const [options, setOptions] = useState(false);

    return (
        <>
            <Grid item xs={12} lg={12} sm={12} md={12}>
                <FormControl fullWidth>
                    <FormLabel className="language_heading">
                        {t(`${props.title}`)}
                    </FormLabel>
                    <div>
                        <Autocomplete
                            style={{
                                borderColor: '#475569'
                            }}
                            id="combo-box-demo"
                            data-test-id='custom-auto-complete'
                            options={props.options}
                            getOptionLabel={(option) => option.title}
                            onChange={(event: any, value: any) => props.onChange(event, value)}
                            open={options}
                            value={props.initialValue}
                            onOpen={() => setOptions(true)}
                            onClose={() => setOptions(false)}
                            popupIcon={<ExpandMoreIcon />}
                            disablePortal
                            blurOnSelect
                            PaperComponent={({ children }) => {
                                return (
                                    <CustomPaper >
                                        <Divider style={{
                                            marginTop: "4px",
                                            marginBottom: "12px",
                                            marginLeft: "12px",
                                            marginRight: "12px"
                                        }} />
                                        {children}
                                    </CustomPaper>
                                );
                            }}
                            renderOption={({ title }) =>
                                <Box>
                                    <ListItemText
                                        primary={title}
                                        className="dropdown_options"
                                    />
                                </Box>
                            }
                            renderInput={(params) =>
                                <CustomTextField {...params}
                                    label=""
                                    className={options ? "enableCont" : "disableCont"}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: false }}
                                    placeholder={props.placeholderText}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {
                                            padding: '8px',
                                            color: '#1E293B',
                                            fontSize: '16px',
                                            fontFamily: "Nunito-Regular",
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                        },
                                    }}

                                />}
                        />
                    </div>
                </FormControl>
            </Grid>
        </>

    );
};

export default LanguageDropDown;


