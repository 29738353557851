// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase';
import { connect } from 'react-firebase'
import { Redirect, Route } from 'react-router-dom';
import HomeScreen from "../../components/src/HomeScreen";
import { Wrapper } from "../../components/src/NativeWebRouteWrapper/index";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ContentFlag from "../../blocks/ContentFlag/src/ContentFlag";
import Translation from "../../blocks/translation/src/Translation";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Download from "../../blocks/Download/src/Download";
import SubscriptionBilling2 from "../../blocks/SubscriptionBilling2/src/SubscriptionBilling2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Videos from "../../blocks/videos/src/Videos";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import JobListing2 from "../../blocks/JobListing2/src/JobListing2";
import OfflineWork from "../../blocks/OfflineWork/src/OfflineWork";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import CourseCreation2 from "../../blocks/CourseCreation2/src/CourseCreation2";
import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications.web";
import ContentModeration from "../../blocks/ContentModeration/src/ContentModeration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Settings5 from "../../blocks/Settings5/src/Settings5.web";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import DataStorage from "../../blocks/DataStorage/src/DataStorage";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import BulletinBoard2 from "../../blocks/BulletinBoard2/src/BulletinBoard2.web";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CertificationTracking from "../../blocks/CertificationTracking/src/CertificationTracking";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Webinars from "../../blocks/Webinars/src/Webinars";
import HashtagMapping from "../../blocks/HashtagMapping/src/HashtagMapping";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups.web";
import CvresumeCandidateManagement2 from "../../blocks/CvresumeCandidateManagement2/src/CvresumeCandidateManagement2";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue.web";
import LanguageOptions from "../../blocks/LanguageOptions/src/LanguageOptions";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import Hashtags from "../../blocks/Hashtags/src/Hashtags";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import PerformanceTracker from "../../blocks/PerformanceTracker/src/PerformanceTracker";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import UserProfileBasicBlockWeb from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import CommunityForumWeb from "../../blocks/CommunityForum/src/CommunityForum.web";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import StoreLocator from "../../blocks/StoreLocator/src/StoreLocator";
import { MuiThemeProvider } from '@material-ui/core/styles';
import muiTheme from './theme';
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import {
  isUserloggedIn,
} from '../../components/src/NativeWebRouteWrapper/Utils';
import '../../components/src/LanguageTranslation/configi18n';
import i18next from "i18next";
import MembersDirectory from '../../blocks/memberdirectory/src/MemberDirectory.web'
import MemberDetails from '../../blocks/memberdirectory/src/MemberDetails.web'
const routeMap = {
  TermsConditions:{
    component:TermsConditions,
   path:[
    "/TermsConditionWeb",
    "/PrivacyPolicyWeb" 
  ]},
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  AutomaticCheckoutCalculation2: {
    component: AutomaticCheckoutCalculation2,
    path: "/AutomaticCheckoutCalculation2"
  },
  VideoLibrary: {
    component: VideoLibrary,
    path: "/VideoLibrary"
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: "/Ordermanagement"
  },
  OrderDetails: {
    component: OrderDetails,
    path: "/OrderDetails"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ContentFlag: {
    component: ContentFlag,
    path: "/ContentFlag"
  },
  Translation: {
    component: Translation,
    path: "/Translation"
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: "/ShoppingCartOrders"
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: "/AddShoppingCartOrderItem"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  SubscriptionBilling2: {
    component: SubscriptionBilling2,
    path: "/SubscriptionBilling2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: "/PeopleManagement2"
  },
  JobListing2: {
    component: JobListing2,
    path: "/JobListing2"
  },
  OfflineWork: {
    component: OfflineWork,
    path: "/OfflineWork"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  CourseCreation2: {
    component: CourseCreation2,
    path: "/CourseCreation2"
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: "/StripeIntegration"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  ContentModeration: {
    component: ContentModeration,
    path: "/ContentModeration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  DataStorage: {
    component: DataStorage,
    path: "/DataStorage"
  },
  ProductDescription: {
    component: ProductDescription,
    path: "/ProductDescription"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  BulletinBoard2: {
    component: BulletinBoard2,
    path: "/BulletinBoard2"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  CertificationTracking: {
    component: CertificationTracking,
    path: "/CertificationTracking"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Webinars: {
    component: Webinars,
    path: "/Webinars"
  },
  HashtagMapping: {
    component: HashtagMapping,
    path: "/HashtagMapping"
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: "/UploadMedia2"
  },
  AccountGroups: {
    component: AccountGroups,
    path:[
      "/AccountGroups",
      "/ViewAllGroups",
      "/CreateNewGroup",
    ]
  },
  CvresumeCandidateManagement2: {
    component: CvresumeCandidateManagement2,
    path: "/CvresumeCandidateManagement2"
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: "/LanguageSupport"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  AutomaticReminders: {
    component: AutomaticReminders,
    path: "/AutomaticReminders"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Catalogue: {
    component: Catalogue,
    path: "/ResourseHub"
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: "/LanguageOptions"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/SignUp"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: "/EmailNotifications2"
  },
  FileAttachment: {
    component: FileAttachment,
    path: "/FileAttachment"
  },
  Hashtags: {
    component: Hashtags,
    path: "/Hashtags"
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: "/ElasticSearch"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  PerformanceTracker: {
    component: PerformanceTracker,
    path: "/PerformanceTracker"
  },
  CommunityForumWeb: {
    component: CommunityForumWeb,
    path: '/CommunityForumWeb'
  },
  // LandingPage:{
  //  component:LandingPage,
  // path:"/LandingPage"},
  CommunityForum: {
    component: CommunityForum,
    path: "/CommunityForum"
  },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: "/PhotoLibrary3"
  },
  StoreLocator: {
    component: StoreLocator,
    path: "/StoreLocator"
  },
  StoreLocator: {
    component: StoreLocator,
    path: "/StoreLocator"
  },
  UserProfileBasicBlockWeb: {
    component: UserProfileBasicBlockWeb,
    path: "/UserProfileBasicBlockWeb"
  },
  MembersDirectory:{
    component: MembersDirectory,
    path: "/MembersDirectory"
  },
  MemberDetails:{
    component: MemberDetails,
    path: "/MemberDetails"
  }
}

const unAuthorisedRoute = ['SignUp','TermsConditionWeb','PrivacyPolicyWeb','ForgotPassword'];
const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {
    const brwLang = navigator.language;
    console.log('detect',brwLang);
    i18next.changeLanguage(brwLang);
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <MuiThemeProvider theme={muiTheme}>
        <View style={{ height: '100vh' }}>
          {/* <TopNav /> */}

          {isUserloggedIn() && (window.location.pathname.substring(1) == "") ? (
           <Redirect to="/CommunityForumWeb" />
          ) : (isUserloggedIn() === false) && (!unAuthorisedRoute.includes(window.location.pathname.substring(1))) ?
            (
              <>
                <Route
                  key={"/"}
                  path={"/"}
                  exact={true}
                  render={props => (
                    <Wrapper element={<LandingPage />} {...props} routeMap={routeMap} />
                  )}
                />
                <Redirect to="/" />
              </>
            ) :
            (
              WebRoutesGenerator({ routeMap })
            )}
          <ModalContainer />
        </View>
      </MuiThemeProvider>
    );
  }
}

export default App;
